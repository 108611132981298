<template>
    <div class="wishlist">
        <div class="wishlist-title">
            <div class="back" @click="backClick">
                <img src="@/assets/images/personal/z.png" />
                <span>Back</span>
            </div>
            <h5>Wishlist</h5>
        </div>
        <div class="wishlist-main" v-show="wishSelect">
            <div class="main-collect" v-if="wishList.length > 0">
                <div class="collect-title">
                    <div>
                        <el-select v-model="value" placeholder="Select">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </div>
                    <span @click="selectClick">Select</span>
                </div>
                <div class="collect-main">
                    <div class="item" v-for="(item, index) in wishList" :key="index">
                        <div class="img">
                            <el-image :lazy-src="item.sku_image" :src="item.sku_image" fit="cover" style="cursor: pointer;height: 100%;"></el-image>
                            <!-- <img :src="item.imgUrl" /> -->
                        </div>
                        <span>{{ item.goods_name }}</span>
                        <p>{{ strCurXSoyego }}{{ item.discount_price }}</p>
                        <img src="@/assets/images/personal/star1.png" />
                    </div>
                </div>
            </div>
            <div class="main-like">
                <h5>You may also like</h5>
                <div class="like">
                    <div class="item" v-for="(item, index) in likeList" :key="index">
                        <div class="img">
                            <el-image :lazy-src="item.goods_image[0]" :src="item.goods_image[0]" fit="cover" style="cursor: pointer;height: 100%;"></el-image>
                        </div>
                        <span>{{ item.goods_name }}</span>
                        <p>${{ item.discount_price }}</p>
                        <!-- <img src="@/assets/images/personal/star.png" /> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="wishlist-main" v-show="!wishSelect">
            <div class="main-select">
                <div class="select-title">
                    <div>
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Select all</el-checkbox>
                    </div>
                    <div class="text">
                        <span @click="deleteClick">Delete({{checkedCities.length}})</span>
                        <p @click="wishSelect = true">Done</p>
                    </div>
                </div>
                <div class="select-main">
                    <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                        <div class="item" v-for="(item, index) in wishList" :key="index">
                            <div class="img">
                                <el-image :lazy-src="item.sku_image" :src="item.sku_image" fit="cover" style="cursor: pointer;height: 100%;"></el-image>
                                <!-- <img :src="item.imgUrl" /> -->
                            </div>
                            <span>{{ item.goods_name }}</span>
                            <p>{{ strCurXSoyego }}{{ item.discount_price }}</p>
                            <el-checkbox size="medium" :label="item.label">&nbsp;</el-checkbox>
                        </div>
                    </el-checkbox-group>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="deleteWish" @closed="handleClose" width="23%" style="margin-top: 20vh">
            <deleteWish @deteleNO="deleteNo" @deteleYes="deleteYes" :ids="ids"></deleteWish>
        </el-dialog>
    </div>
</template>

<style>
.wishlist .el-input__inner {
    border-radius: 0;
}
.wishlist .el-checkbox__inner {
    background-color: rgba(29, 29, 29, 0.3);
    border-color: #FFFFFF;
    border-radius: 50%;
    width: 18px;
    height: 18px;  
}

.wishlist .el-checkbox__input.is-checked .el-checkbox__inner,
.wishlist .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #330000;
    background-color: #330000;
    color: #330000;
    width: 18px;
    height: 18px;
}
.wishlist .el-checkbox__inner::after{
    left: 6px;
    top: 3px;
}
.wishlist .el-checkbox__input.is-indeterminate .el-checkbox__inner::before{
    top: 7px;
}
.wishlist .el-checkbox__input.is-checked + .el-checkbox__label{
    color: #330000;
}
.wishlist .el-checkbox__input.is-focus .el-checkbox__inner{
    border-color: #FFFFFF;
    width: 18px;
    height: 18px;
}

.about-content{
    background: #FFFFFF;
}
</style>
<style lang="scss" scoped>
@import "./css/wishlist.scss";
</style>

<script>
import wishlist from "./js/wishlist.js"
import deleteWish from "./alert/deleteWish"

export default {
    name: "wishlist",
    components: {
        deleteWish
    },
    mixins: [wishlist]
}
</script>