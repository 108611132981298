import { goodsCollect, deleteGoods } from "@/api/member/collection"
import { goodsSkuPage } from "@/api/goods/goods"

export default {
    name: "wishlist",
    components: {

    },
    data: () => {
        return {
            value: '',
            options: [{
                label: "Recently Added",
                value: 0
            }, {
                label: "Most Popular",
                value: 1
            }, {
                label: "New Arrivals",
                value: 2
            }, {
                label: "Price Low To High",
                value: 3
            }, {
                label: "Price High To Low",
                value: 4
            }],
            wishList: [],
            likeList: [],
            wishSelect: true,
            checkAll: false,
            checkedCities: [],
            isIndeterminate: true,
            deleteWish: false,
            strCurXSoyego: localStorage.getItem("strCurXSoyego"),
            ids: []
        }
    },
    watch: {
        '$route': 'fetchData',
        checkedCities: {
            deep: true,
            handler: function(val){
                this.checkedCities = val;
                // this.fetchData();
            },
        },
        checkAll: {
            deep: true,
            handler: function(val){
                this.checkAll = val;
                // this.fetchData();
            },
        }
    },
    created() {
        this.getWishList();
        this.getGoodsList();
    },
    mounted() {

    },
    computed: {

    },
    methods: {
        getGoodsList() {
            // console.log(this.id)
            const params = {
                page: 1,
                page_size: 8,
                attr_rand: true
            };
            goodsSkuPage(params || {}).then(res => {
                // console.log(res.data)
                if (res.code == 0 && res.data) {
                    let data = res.data;
                    data.list.forEach(item => {
                        item.goods_image = item.goods_image.split(',');
                    });
                    this.likeList = data.list;
                }
            }).catch(err => {
                this.loadRight = false;
                this.$message.error(err.message);
            });
        },
        getWishList() {
            let params = {
                page: 1,                
                page_size: 40
            }
            goodsCollect(params).then(res => {
                if(res.code == 0 && res.data) {
                    res.data.list.forEach((item, index) => {
                        this.$set(item, "label", index);
                    })
                    this.wishList = res.data.list;
                    // console.log(this.wishList)
                }
            }).catch(err => {
                // console.log(err)
                this.$message.error(err.message);
            });
        },
        
        backClick() {
            this.$router.go(-1);
        },
        selectClick() {
            this.wishSelect = !this.wishSelect;
            // this.fetchData();
        },
        handleCheckAllChange(val) {
            let options = [];
            this.wishList.forEach((item, index) => {
                options[index] = item.label;
            });
            this.checkedCities = val ? options : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.wishList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.wishList.length;
        },
        handleClose() {
            this.deleteWish = false;
        },
        deleteNo(val) {
            this.deleteWish = val;
        },
        deleteYes(val) {
            this.deleteWish = val;
            this.$message({ message: 'Deleted successfully', type: 'success' });
            this.getWishList();
            // console.log(this.wishList)
            this.wishSelect = true;
        },
        deleteClick() {
            if(this.checkedCities.length > 0){
                this.wishList.forEach((item, index) => {
                    if(this.checkedCities.indexOf(item.label) != -1){
                        this.ids[index] = item.goods_id;
                    }
                })
                this.deleteWish = true;
            }else{
                this.$message({ message: 'Please select the item to delete', type: 'warning' });
            }
        }
    }
}
